import { registerApplication, start } from 'single-spa'

// window.addEventListener("single-spa:app-change", (evt) => {
//   console.log("evt: ", evt);
//   // we can use this to toggle loader
// });

const biskoModules = [
  {
    name: 'analytics',
    activeWhen: (location) => /^\/bisko\/\w+\/analytics/.test(location.pathname)
  },
  {
    name: 'audience',
    activeWhen: (location) => /^\/bisko\/\w+\/audience/.test(location.pathname)
  },
  {
    name: 'data',
    activeWhen: (location) => /^\/bisko\/\w+\/data/.test(location.pathname)
  },
  {
    name: 'lookalike',
    activeWhen: (location) => /^\/bisko\/\w+\/lookalike/.test(location.pathname)
  },
  {
    name: 'organization',
    activeWhen: (location) =>
      /^\/bisko\/\w+\/organization/.test(location.pathname)
  },
  {
    name: 'visualizer',
    activeWhen: (location) =>
      /\/?(?:preview\/)?\/bisko\/\w+\/visualizer/.test(location.pathname)
  },
  {
    name: 'biskql',
    activeWhen: (location) => /^\/bisko\/\w+\/biskql/.test(location.pathname)
  }
]

const portaModules = [
  {
    name: 'home',
    activeWhen: (location) => /^\/porta\/\w+\/home/.test(location.pathname)
  },
  {
    name: 'clients',
    activeWhen: (location) => /^\/porta\/\w+\/clients\//.test(location.pathname)
  },
  {
    name: 'user-management',
    activeWhen: (location) =>
      /^\/porta\/\w+\/user-management\//.test(location.pathname)
  },
  {
    name: 'customization',
    activeWhen: (location) =>
      /^\/porta\/\w+\/(customization|user-profile)\//.test(location.pathname)
  },
  {
    name: 'settings',
    activeWhen: (location) =>
      /^\/porta\/\w+\/(settings|authentication|security|integrations)\//.test(
        location.pathname
      )
  }
]

const addLoader = () => {
  const spinnerElem = document.createElement('span')
  spinnerElem.classList.add('loader-spinner')
  const wrapperElem = document.createElement('div')
  wrapperElem.classList.add('module-loader')
  wrapperElem.appendChild(spinnerElem)
  document.body.appendChild(wrapperElem)
}

const removeLoader = () => {
  document.querySelector('.module-loader')?.remove()
}

registerApplication({
  name: 'Nodus',
  app: () => {
    return System.import('nodus')
      .then((res) => {
        console.log('Nodus module: ', res)
        return res
      })
      .catch((err) => {
        console.log('Error loading Nodus module: ', err)
      })
  },
  activeWhen: (location) => /^^(?!\/preview\/).*/.test(location.pathname)
})

registerApplication({
  name: '@nodus/navigation',
  app: () => {
    return System.import('@nodus/navigation')
      .then((res) => {
        console.log('@nodus/navigation module: ', res)
        return res
      })
      .catch((err) => {
        console.log('Error loading @nodus/navigation module: ', err)
      })
  },
  activeWhen: (location) =>
    /^\/(?!organizations\/|error\/|preview\/).+\//.test(location.pathname)
})

biskoModules.forEach((module) => {
  registerApplication({
    name: `@bisko/${module.name}`,
    app: () => {
      addLoader()
      return System.import(`@bisko/${module.name}`)
        .then((res) => {
          console.log(`@bisko/${module.name} module: `, res)
          removeLoader()
          return res
        })
        .catch((err) => {
          console.log(`Error loading @bisko/${module.name} module: `, err)
          removeLoader()
        })
    },
    activeWhen: module.activeWhen
  })
})

portaModules.forEach((module) => {
  registerApplication({
    name: `@porta/${module.name}`,
    app: () => {
      addLoader()
      return System.import(`@porta/${module.name}`)
        .then((res) => {
          console.log(`@porta/${module.name} module: `, res)
          removeLoader()
          return res
        })
        .catch((err) => {
          console.log(`Error loading @porta/${module.name} module: `, err)
          removeLoader()
        })
    },
    activeWhen: module.activeWhen
  })
})

start({
  urlRerouteOnly: true
})
